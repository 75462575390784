import { FieldNamesProps } from "@/types/common.types";
import { isArray } from "./is";

/**
 * @description Get localStorage
 * @param {String} key Storage name
 * @returns {String}
 */
export function localGet(key: string) {
  const value = window?.localStorage.getItem(key);
  try {
    return JSON.parse(window?.localStorage.getItem(key) as string);
  } catch {
    return value;
  }
}

/**
 * @description Store localStorage
 * @param {String} key Storage name
 * @param {*} value Storage value
 * @returns {void}
 */
export function localSet(key: string, value: any) {
  window?.localStorage.setItem(key, JSON.stringify(value));
}

/**
 * @description Remove localStorage
 * @param {String} key Storage name
 * @returns {void}
 */
export function localRemove(key: string) {
  window?.localStorage.removeItem(key);
}

/**
 * @description Clear all localStorage
 * @returns {void}
 */
export function localClear() {
  window?.localStorage.clear();
}

/**
 * @description Determine data type
 * @param {*} val Data to be type-checked
 * @returns {String}
 */
export function isType(val: any) {
  if (val === null) return "null";
  if (typeof val !== "object") return typeof val;
  else
    return Object.prototype.toString.call(val).slice(8, -1).toLocaleLowerCase();
}

/**
 * @description Generate unique uuid
 * @returns {String}
 */
export function generateUUID() {
  let uuid = "";
  for (let i = 0; i < 32; i++) {
    const random = (Math.random() * 16) | 0;
    if (i === 8 || i === 12 || i === 16 || i === 20) uuid += "-";
    uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
  }
  return uuid;
}

/**
 * Determine if two objects are equal
 * @param {Object} a Object to compare one
 * @param {Object} b Object to compare two
 * @returns {Boolean} Returns true if equal, false otherwise
 */
export function isObjectValueEqual(
  a: { [key: string]: any },
  b: { [key: string]: any }
) {
  if (!a || !b) return false;
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);
  if (aProps.length != bProps.length) return false;
  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];
    const propA = a[propName];
    const propB = b[propName];
    if (!b.hasOwnProperty(propName)) return false;
    if (propA instanceof Object) {
      if (!isObjectValueEqual(propA, propB)) return false;
    } else if (propA !== propB) {
      return false;
    }
  }
  return true;
}

/**
 * @description Generate random number
 * @param {Number} min Minimum value
 * @param {Number} max Maximum value
 * @returns {Number}
 */
export function randomNum(min: number, max: number): number {
  const num = Math.floor(Math.random() * (min - max) + max);
  return num;
}

/**
 * @description Get greeting based on current time
 * @returns {String}
 */
export function getTimeState() {
  const timeNow = new Date();
  const hours = timeNow.getHours();
  if (hours >= 6 && hours <= 10) return `Good morning ⛅`;
  if (hours >= 10 && hours <= 14) return `Good noon 🌞`;
  if (hours >= 14 && hours <= 18) return `Good afternoon 🌞`;
  if (hours >= 18 && hours <= 24) return `Good evening 🌛`;
  if (hours >= 0 && hours <= 6) return `Good early morning 🌛`;
}

/**
 * @description Get browser default language
 * @returns {String}
 */
export function getBrowserLang() {
  const browserLang = navigator.language
    ? navigator.language
    : navigator.browserLanguage;
  let defaultBrowserLang = "";
  if (["cn", "zh", "zh-cn"].includes(browserLang.toLowerCase())) {
    defaultBrowserLang = "zh";
  } else {
    defaultBrowserLang = "en";
  }
  return defaultBrowserLang;
}

// /**
//  * @description Use recursion to flatten the menu for easy addition of dynamic routes
//  * @param {Array} menuList Menu list
//  * @returns {Array}
//  */
// export function getFlatMenuList(menuList: Menu.IRouteMenuOptions[]): Menu.IRouteMenuOptions[] {
//   let newMenuList: Menu.IRouteMenuOptions[] = JSON.parse(JSON.stringify(menuList));
//   return newMenuList.flatMap(item => [item, ...(item.children ? getFlatMenuList(item.children) : [])]);
// }

// /**
//  * @description Use recursion to filter out the list that needs to be rendered in the left menu (need to remove menus with isHide == true)
//  * @param {Array} menuList Menu list
//  * @returns {Array}
//  * */
// export function getShowMenuList(menuList: Menu.IRouteMenuOptions[]) {
//   let newMenuList: Menu.IRouteMenuOptions[] = JSON.parse(JSON.stringify(menuList));
//   return newMenuList.filter(item => {
//     item.children?.length && (item.children = getShowMenuList(item.children));
//     return !item.meta?.isHide;
//   });
// }

// /**
//  * @description Use recursion to find all breadcrumbs and store them in pinia/vuex
//  * @param {Array} menuList Menu list
//  * @param {Array} parent Parent menu
//  * @param {Object} result Processed result
//  * @returns {Object}
//  */
// export const getAllBreadcrumbList = (menuList: Menu.IRouteMenuOptions[], parent = [], result: { [key: string]: any } = {}) => {
//   for (const item of menuList) {
//     result[item.path] = [...parent, item];
//     if (item.children) getAllBreadcrumbList(item.children, result[item.path], result);
//   }
//   return result;
// };

// /**
//  * @description Use recursion to process route menu paths, generating a one-dimensional array (used in the first version of local route authentication, this function is currently not in use)
//  * @param {Array} menuList All menu list
//  * @param {Array} menuPathArr One-dimensional array of menu addresses ['**','**']
//  * @returns {Array}
//  */
// export function getMenuListPath(menuList: Menu.IRouteMenuOptions[], menuPathArr: string[] = []): string[] {
//   for (const item of menuList) {
//     if (typeof item === "object" && item.path) menuPathArr.push(item.path);
//     if (item.children?.length) getMenuListPath(item.children, menuPathArr);
//   }
//   return menuPathArr;
// }

// /**
//  * @description Recursively query the menu object corresponding to the current path (this function is currently not in use)
//  * @param {Array} menuList Menu list
//  * @param {String} path Current access address
//  * @returns {Object | null}
//  */
// export function findMenuByPath(menuList: Menu.IRouteMenuOptions[], path: string): Menu.IRouteMenuOptions | null {
//   for (const item of menuList) {
//     if (item?.path === path) return item;
//     if (item?.children) {
//       const res = findMenuByPath(item.children, path);
//       if (res) return res;
//     }
//   }
//   return null;
// }

// /**
//  * @description Use recursion to filter the menu names that need to be cached (This function is not currently in use)
//  * @param {Array} menuList All menu list
//  * @param {Array} keepAliveNameArr Cached menu names ['**','**']
//  * @returns {Array}
//  * */
// export function getKeepAliveRouterName(menuList: Menu.IRouteMenuOptions[], keepAliveNameArr: string[] = []) {
//   menuList.forEach(item => {
//     item.meta?.isKeepAlive && item.name && keepAliveNameArr.push(item.name);
//     item?.children?.length && getKeepAliveRouterName(item.children, keepAliveNameArr);
//   });
//   return keepAliveNameArr;
// }

/**
 * @description Format table cell default value (el-table-column)
 * @param {Number} row Row
 * @param {Number} col Column
 * @param {*} callValue Current cell value
 * @returns {String}
 * */
export function formatTableColumn(row: number, col: number, callValue: any) {
  // If the current value is an array, join with / (customize according to requirements)
  if (isArray(callValue))
    return callValue.length ? callValue.join(" / ") : "--";
  return callValue ?? "--";
}

/**
 * @description Handle ProTable values that are arrays || no data
 * @param {*} callValue Value to be processed
 * @returns {String}
 * */
export function formatValue(callValue: any) {
  // If the current value is an array, join with / (customize according to requirements)
  if (isArray(callValue))
    return callValue.length ? callValue.join(" / ") : "--";
  return callValue ?? "--";
}

/**
 * @description Handle cases where prop is multi-level nested, return the data (e.g., prop: user.name)
 * @param {Object} row Current row data
 * @param {String} prop Current prop
 * @returns {*}
 * */
export function handleRowAccordingToProp(
  row: { [key: string]: any },
  prop: string
) {
  if (!prop.includes(".")) return row[prop] ?? "--";
  prop.split(".").forEach((item) => (row = row[item] ?? "--"));
  return row;
}

/**
 * @description Process prop, when prop is multi-level nested ==> return the last level prop
 * @param {String} prop Current prop
 * @returns {String}
 * */
export function handleProp(prop: string) {
  const propArr = prop.split(".");
  if (propArr.length == 1) return prop;
  return propArr[propArr.length - 1];
}

/**
 * @description Query the required data based on the enum list (if label and value key values are specified, it will automatically recognize and format)
 * @param {String} callValue Current cell value
 * @param {Array} enumData Dictionary list
 * @param {Array} fieldNames Key values for label && value && children
 * @param {String} type Filter type (currently only tag)
 * @returns {String}
 * */
export function filterEnum(
  callValue: any,
  enumData?: any,
  fieldNames?: FieldNamesProps,
  type?: "tag"
) {
  const value = fieldNames?.value ?? "value";
  const label = fieldNames?.label ?? "label";
  const children = fieldNames?.children ?? "children";
  let filterData: { [key: string]: any } = {};
  // Check if enumData is an array
  if (Array.isArray(enumData))
    filterData = findItemNested(enumData, callValue, value, children);
  // Check if the output result is of tag type
  if (type == "tag") {
    return filterData?.tagType ? filterData.tagType : "";
  } else {
    return filterData ? filterData[label] : "--";
  }
}

/**
 * @description Recursively find the enum value corresponding to callValue
 * */
export function findItemNested(
  enumData: any,
  callValue: any,
  value: string,
  children: string
) {
  return enumData.reduce((accumulator: any, current: any) => {
    if (accumulator) return accumulator;
    if (current[value] === callValue) return current;
    if (current[children])
      return findItemNested(current[children], callValue, value, children);
  }, null);
}
