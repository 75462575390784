import { PATH_NAME } from "@/constants/path-name.constant";
import { ResultEnum } from "@/enums/httpEnum";
import { checkStatus } from "@/helper/checkStatus";
import { ApiResponse, ResultData } from "@/types/common.types";
import { localGet } from "@/utils/common";
import { removeCookie } from "@/utils/cookies";
import { localClear } from "@/utils/index";

interface RequestConfig extends RequestInit {
  loading?: boolean;
  params?: object;
}

class RequestHttp {
  private baseURL: string;
  private defaultConfig: RequestConfig;

  constructor() {
    this.baseURL =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000"
        : process.env.NEXT_PUBLIC_VERCEL_URL ||
          process.env.NEXT_PUBLIC_DOMAIN ||
          "https://release.d20ri1c7edms3g.amplifyapp.com";

    this.defaultConfig = {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  private async handleResponse<T>(response: Response): Promise<ResultData<T>> {
    if (!response.ok) {
      if (typeof window !== "undefined") {
        if (!window?.navigator.onLine) {
          window.location.href = PATH_NAME.ERROR_500;
        }
      }
      checkStatus(response.status);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: ApiResponse<T> = await response.json();

    if (typeof window !== "undefined") {
      if (Number(data.code) === ResultEnum.OVERDUE) {
        localClear();
        removeCookie("authToken");
        window.location.href = PATH_NAME.SIGN_IN;
        throw new Error("Session expired");
      }

      if (data.code && Number(data.code) !== ResultEnum.SUCCESS) {
        throw data;
      }
    }

    return data;
  }

  private getHeaders(): Headers {
    const headers = new Headers(this.defaultConfig.headers);

    if (typeof window !== "undefined") {
      const token = localGet("auth-storage")?.state?.token ?? null;
      if (token) {
        headers.set("Authorization", token);
        headers.set("x-access-token", token);
      }
    }

    return headers;
  }

  private createUrl(baseUrl: string, url: string, params?: object): string {
    const fullUrl = new URL(url, baseUrl);
    if (params) {
      Object.entries(params)
        .filter(([_, value]) => value !== undefined && value !== "")
        .forEach(([key, value]) => {
          fullUrl.searchParams.append(key, String(value));
        });
    }
    return fullUrl.toString();
  }

  async get<T>(
    url: string,
    config: RequestConfig = {}
  ): Promise<ResultData<T>> {
    const fullUrl = this.createUrl(this.baseURL, url, config.params);
    const response = await fetch(fullUrl, {
      ...this.defaultConfig,
      ...config,
      method: "GET",
      headers: this.getHeaders(),
    });
    return this.handleResponse<T>(response);
  }

  async post<T>(
    url: string,
    body?: any,
    config: RequestConfig = {}
  ): Promise<ResultData<T>> {
    const response = await fetch(this.createUrl(this.baseURL, url), {
      ...this.defaultConfig,
      ...config,
      method: "POST",
      headers: this.getHeaders(),
      body: JSON.stringify(body),
    });
    return this.handleResponse<T>(response);
  }

  async put<T>(
    url: string,
    body?: any,
    config: RequestConfig = {}
  ): Promise<ResultData<T>> {
    const response = await fetch(this.createUrl(this.baseURL, url), {
      ...this.defaultConfig,
      ...config,
      method: "PUT",
      headers: this.getHeaders(),
      body: JSON.stringify(body),
    });
    return this.handleResponse<T>(response);
  }

  async delete<T>(
    url: string,
    config: RequestConfig = {}
  ): Promise<ResultData<T>> {
    const response = await fetch(
      this.createUrl(this.baseURL, url, config.params),
      {
        ...this.defaultConfig,
        ...config,
        method: "DELETE",
        headers: this.getHeaders(),
      }
    );
    return this.handleResponse<T>(response);
  }

  async download(
    url: string,
    body?: any,
    config: RequestConfig = {}
  ): Promise<Blob> {
    const response = await fetch(this.createUrl(this.baseURL, url), {
      ...this.defaultConfig,
      ...config,
      method: "POST",
      headers: this.getHeaders(),
      body: JSON.stringify(body),
    });
    return response.blob();
  }

  // Upload service methods
  async postUploadService<T>(
    url: string,
    body?: any,
    config: RequestConfig = {}
  ): Promise<ResultData<T>> {
    const response = await fetch(this.createUrl(this.baseURL, url), {
      ...this.defaultConfig,
      ...config,
      method: "POST",
      headers: this.getHeaders(),
      body: body instanceof FormData ? body : JSON.stringify(body),
    });
    return this.handleResponse<T>(response);
  }

  async putUploadService<T>(
    url: string,
    body?: any,
    config: RequestConfig = {}
  ): Promise<ResultData<T>> {
    const response = await fetch(this.createUrl(this.baseURL, url), {
      ...this.defaultConfig,
      ...config,
      method: "PUT",
      headers: this.getHeaders(),
      body: body instanceof FormData ? body : JSON.stringify(body),
    });
    return this.handleResponse<T>(response);
  }
}

const httpInstance = new RequestHttp();
export default httpInstance;
