/**
 * @description: Check network request status code
 * @param {Number} status
 * @return void
 */
export const checkStatus = (status: number) => {
  switch (status) {
    case 400:
      console.error("Request failed! Please try again later.");
      break;
    case 401:
      console.error("Login invalid! Please log in again.");
      break;
    case 403:
      console.error("You do not have permission to access this resource!");
      break;
    case 404:
      console.error("The resource you are accessing does not exist!");
      break;
    case 405:
      console.error("Request method error! Please try again later.");
      break;
    case 408:
      console.error("Request timeout! Please try again later.");
      break;
    case 500:
      console.error("Service exception!");
      break;
    case 502:
      console.error("Gateway error!");
      break;
    case 503:
      console.error("Service unavailable!");
      break;
    case 504:
      console.error("Gateway timeout!");
      break;
    default:
      console.error("Request failed!");
  }
};
